import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';
import ResultsAndPresentationsArticles from 'components/ResultsAndPresentationsArticles';

import { ResultsAndPresentationsPageTypes } from './models';
import ResultsAndPresentationsBannerRenderer from './ResultsAndPresentationsBannerRenderer';
import ResultsAndPresentationsBodyRenderer from './ResultsAndPresentationsBodyRenderer';

import './ResultsAndPresentationsPage.scss';

const ResultsAndPresentationsPage: FC<ResultsAndPresentationsPageTypes.ResultsAndPresentationsPageType> =
  ({ data: { resultsAndPresentationsPage } }) => {
    const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
      resultsAndPresentationsPage.seo;
    const {
      level,
      lang,
      title,
      banner,
      articlesSourcePage,
      numberOfResultsPerLoad,
      resultsMainTag,
      resultsSubTags,
      viewPageLinkText,
      pressReleaseLinkText,
      helperFields,
      bottomModules,
      url,
    } = resultsAndPresentationsPage;

    return (
      <Layout headerTransparent className="results-and-presentation-page">
        <Seo
          {...{
            seo: resultsAndPresentationsPage.seo,
            openGraph: resultsAndPresentationsPage.openGraph,
            pageTitle: title,
            seoExternalHreflangs,
            pageUrl: url,
            lang,
          }}
        />
        <PageSchema
          type="WebPage"
          name={seoMetaTitle}
          data={{
            metaTitle: seoMetaTitle,
            metaDescription: seoMetaDescription,
            metaKeywords: seoMetaKeywords,
          }}
        />
        <div className="banners">
          <Breadcrumbs level={level} />
          {banner && <ResultsAndPresentationsBannerRenderer {...banner[0]} />}
        </div>

        <ResultsAndPresentationsArticles
          articlesSourcePageId={articlesSourcePage}
          resultsMainTag={resultsMainTag}
          resultsSubTags={resultsSubTags}
          numberOfResultsPerLoad={numberOfResultsPerLoad}
          viewPageLinkText={viewPageLinkText}
          pressReleaseLinkText={pressReleaseLinkText}
          lang={lang}
          {...helperFields}
        />

        <div id="modules">
          <ResultsAndPresentationsBodyRenderer modules={bottomModules} />
        </div>
      </Layout>
    );
  };

export const query = graphql`
  query ($url: String) {
    resultsAndPresentationsPage(url: { eq: $url }) {
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentResultsAndPresentationsBanners
      }
      articlesSourcePage
      numberOfResultsPerLoad
      resultsMainTag {
        name
      }
      resultsSubTags {
        name
      }
      viewPageLinkText
      pressReleaseLinkText
      helperFields {
        ...FragmentArticlesFiltersExtendedHelperFields
      }
      bottomModules {
        ...FragmentResultsAndPresentationsModules
      }
    }
  }
`;

export default ResultsAndPresentationsPage;
