import React, { FC } from 'react';

import BannerWithResultsCard from 'components/BannerWithResultsCard';
import SimpleBanner from 'components/SimpleBanner';

import { ResultsAndPresentationsBannerRendererProps } from './models';

const Components = {
  BannerWithResultsCard,
  SimpleBanner,
};

const ResultsAndPresentationsBannerRenderer: FC<ResultsAndPresentationsBannerRendererProps> = (
  banner
) => {
  if (typeof Components[banner.__typename] === 'undefined') {
    return null;
  }

  const Component = Components[banner.__typename];

  return <Component key={banner.__typename} {...banner} />;
};

export default ResultsAndPresentationsBannerRenderer;
