import React, { FC } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import { Container } from 'layout';

import { CustomResultsAndPresentationHitsProps } from './models';
import ResultsAndPresentationItem from './ResultsAndPresentationItem';

import './CustomResultsAndPresentationHits.scss';

const InfiniteHits = ({
  hits,
  hasMore,
  refineNext,
  lang,
  loadMoreText,
  viewPageLinkText,
  pressReleaseLinkText,
}) => (
  <div className="results-and-presentation">
    <Container>
      <ul className="results-and-presentation__list">
        {hits?.map((hit) => (
          <li className="results-and-presentation__item" key={hit.id}>
            <ResultsAndPresentationItem
              hit={hit}
              lang={lang}
              viewPageLinkText={viewPageLinkText}
              pressReleaseLinkText={pressReleaseLinkText}
            />
          </li>
        ))}
      </ul>
      <button
        type="button"
        className="results-and-presentation-more-btn btn btn--primary btn--pink"
        onClick={refineNext}
        disabled={!hasMore}
      >
        {loadMoreText}
      </button>
    </Container>
  </div>
);

const CustomResultsAndPresentationHits: FC<CustomResultsAndPresentationHitsProps> =
  connectInfiniteHits(InfiniteHits);

export default CustomResultsAndPresentationHits;
