import React, { FC } from 'react';
import { Link } from 'gatsby';

import LinkItem from 'common/LinkItem';
import { formatDisplayDate } from 'utils/dateHandler';

import Arrow from '../../../../common/icons/arrow-right.svg';
import { ResultsAndPresentationItemProps } from './models';

import './ResultsAndPresentationItem.scss';

const ResultsAndPresentationItem: FC<ResultsAndPresentationItemProps> = ({
  hit,
  lang,
  viewPageLinkText,
  pressReleaseLinkText,
}) => (
  <div className="results-and-presentation__item-holder">
    <span className="results-and-presentation__item-year">
      {formatDisplayDate(hit.creationDate, lang)}
    </span>
    <h2 className="results-and-presentation__item-title h5">{hit.title}</h2>
    <p className="results-and-presentation__item-text">{hit.previewText}</p>
    <Link to={hit.url} className="results-and-presentation__item-link link link--pink">
      {viewPageLinkText}
      <Arrow aria-hidden="true" />
    </Link>
    {hit.ctaLinks.length ? (
      <div className="results-and-presentation__item-cta-links">
        {hit.pressReleasePdf ? (
          <LinkItem
            link={hit.pressReleasePdf.ctaLink}
            title={pressReleaseLinkText}
            linkClass="link link--black-grey results-and-presentation__item-cta-link"
            hasIcon
            isLink
            showTitle
          />
        ) : null}
        {hit?.ctaLinks.map((item) => (
          <LinkItem
            link={item.ctaLink}
            title={item.text}
            linkClass="link link--black-grey results-and-presentation__item-cta-link"
            hasIcon
            key={item.ctaLink[0]?.url}
            isLink
            showTitle
          />
        ))}
      </div>
    ) : null}
  </div>
);

export default ResultsAndPresentationItem;
