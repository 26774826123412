import React, { FC } from 'react';

import ContentBlock from 'components/ContentBlock';
import CtaCards from 'components/CtaCards';
import HeadingAndText from 'components/HeadingAndText';
import SimpleCtaBlocks from 'components/SimpleCtaBlocks';
import TitleAndDownload from 'components/TitleAndDownload';
import VideoAndImageWithText from 'components/VideoAndImageWithText';

import { ResultsAndPresentationsBodyRendererProps } from './models';

const Components = {
  'Title And Download': TitleAndDownload,
  'CTA Cards': CtaCards,
  'Video And Image With Text': VideoAndImageWithText,
  'Content Block': ContentBlock,
  'Heading And Text': HeadingAndText,
  'Simple CTA Blocks': SimpleCtaBlocks,
};

const ResultsAndPresentationsBodyRenderer: FC<ResultsAndPresentationsBodyRendererProps> = ({
  modules,
}) => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      return <Component key={`${block.structure}${index}`} module={block.module} />;
    })}
  </>
);

export default ResultsAndPresentationsBodyRenderer;
