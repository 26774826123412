import React, { FC, useEffect, useState } from 'react';
import { Configure } from 'react-instantsearch-dom';

import { Container } from 'layout';
import CustomCurrentRefinements from 'common/CustomCurrentRefinements';
import Filters from 'common/Filters';
import CustomResultsHandler from 'common/Filters/CustomResultsHandler';
import InstantSearchContainer from 'common/InstantSearchContainer';
import SortingHits from 'common/SortingHits';
import { getFilters } from 'utils/articlesFiltersHandler';
import { RESULTS_AND_PRESENTATIONS_NUMBER_OF_ARTICLES } from 'utils/constants';

import CustomResultsAndPresentationHits from './CustomResultsAndPresentationHits';
import { ResultsAndPresentationsArticlesProps } from './models';

import './ResultsAndPresentationsArticles.scss';

const ResultsAndPresentationsArticles: FC<ResultsAndPresentationsArticlesProps> = ({
  articlesSourcePageId,
  numberOfResultsPerLoad,
  resultsMainTag,
  resultsSubTags,
  viewPageLinkText,
  pressReleaseLinkText,
  lang,
  searchPlaceholder,
  searchText,
  yearsDropdownText,
  selectedFiltersText,
  numberOfResultsText,
  clearFiltersText,
  sortByText,
  mostRecentSortText,
  oldestSortText,
  loadMoreButtonText,
  noResultsText,
}) => {
  const [filters, setFilters] = useState<string>('');
  const [cleared, setCleared] = useState<boolean>(false);

  useEffect(() => {
    setFilters(getFilters(resultsMainTag, resultsSubTags, lang));
  }, []);

  const resetClear = () => {
    setCleared(false);
  };

  const clear = () => {
    setCleared(true);
  };

  return (
    <section className="results-and-presentation-section">
      <InstantSearchContainer
        indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-articles`}
        cleared={cleared}
      >
        <Configure
          hitsPerPage={numberOfResultsPerLoad || RESULTS_AND_PRESENTATIONS_NUMBER_OF_ARTICLES}
          numericFilters={[`landingPageId = ${articlesSourcePageId}`]}
          filters={filters}
        />
        <Filters
          searchBtnText={searchText}
          selectedFiltersText={selectedFiltersText}
          searchPlaceholder={searchPlaceholder}
          firstFilterText={yearsDropdownText}
          filters={['year']}
          numberOfResultsText={numberOfResultsText}
          clearFiltersText={clearFiltersText}
          clear={clear}
          resetClear={resetClear}
        />
        <Container>
          <div className="sort-by-and-layout">
            <SortingHits
              sortByText={sortByText}
              firstValue={mostRecentSortText}
              secondValue={oldestSortText}
            />
          </div>
          <CustomCurrentRefinements />
        </Container>
        <CustomResultsHandler noResultsText={noResultsText}>
          <CustomResultsAndPresentationHits
            lang={lang}
            loadMoreText={loadMoreButtonText}
            viewPageLinkText={viewPageLinkText}
            pressReleaseLinkText={pressReleaseLinkText}
          />
        </CustomResultsHandler>
      </InstantSearchContainer>
    </section>
  );
};

export default ResultsAndPresentationsArticles;
